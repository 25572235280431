/**
 * Interactive Floating Leaves Animation
 * 
 * A Three.js application that renders floating leaf models with dust particles
 * and interactive mouse-based movement. The leaves float gently and respond
 * to mouse movement with repulsion effects.
 */

import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

/**
 * Configuration constants
 */
const CONFIG = {
  camera: {
    fov: 35,
    near: 0.1,
    far: 100,
    position: new THREE.Vector3(0, 0, 5)
  },
  particles: {
    count: 300,
    size: { min: 0.05, max: 0.15 },
    radius: 3.5,
    speed: 0.01
  },
  leaves: {
    floatMin: 0.09,
    floatMax: 0.19,
    repulsionRadius: 2.0,
    repulsionStrength: 0.8,
    lerpFactor: 0.1  // Controls smoothness (lower = smoother but slower)
  },
  resources: {
    mtl: 'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_pljcaw.mtl',
    texture: 'https://res.cloudinary.com/dyl1v35zu/image/upload/v1625920260/Leaf_Tex_ugemeg.jpg',
    particleTexture: 'https://threejs.org/examples/textures/sprites/circle.png',
    models: [
      'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_1_r13yhj.obj',
      'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_2_tc6znp.obj',
      'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_3_davoem.obj',
      'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_4_jtpeee.obj',
      'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_5_l0ngyl.obj',
      'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_6_qhb3sl.obj',
      'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_7_y2ovkv.obj',
      'https://res.cloudinary.com/dyl1v35zu/raw/upload/v1621115739/Leaf_8_ux1dgp.obj'
    ]
  }
};

/**
 * Main application class
 */
class LeavesApp {
  constructor(canvasId) {
    // Get canvas element
    this.canvas = document.querySelector(canvasId);
    
    // Three.js essentials
    this.scene = new THREE.Scene();
    this.camera = this.createCamera();
    this.renderer = this.createRenderer();
    this.clock = new THREE.Clock();
    
    // Mouse interaction
    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();
    this.mouseInCanvas = false;
    
    // Scene elements
    this.leaves = [];
    this.particles = null;
    this.particlesData = [];
    
    // Initialize application
    this.setupControls();
    this.setupLighting();
    this.setupMouseHandlers();
    this.setupParticles();
    this.loadModels();
    this.setupResizeHandler();
  }
  
  /**
   * Create and configure the camera
   */
  createCamera() {
    const { fov, near, far, position } = CONFIG.camera;
    const aspect = window.innerWidth / window.innerHeight;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.copy(position);
    return camera;
  }
  
  /**
   * Create and configure the renderer
   */
  createRenderer() {
    const renderer = new THREE.WebGLRenderer({ 
      canvas: this.canvas, 
      alpha: true,
      antialias: true
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    return renderer;
  }
  
  /**
   * Set up camera orbit controls
   */
  setupControls() {
    const controls = new OrbitControls(this.camera, this.canvas);
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.minPolarAngle = Math.PI/2;
    controls.maxPolarAngle = Math.PI/2;
    controls.update();
  }
  
  /**
   * Set up scene lights
   */
  setupLighting() {
    // Ambient light for overall scene illumination
    this.scene.add(new THREE.AmbientLight(0xffffff, 0.5));
    
    // Main spotlight with shadow casting
    const spotLight = new THREE.SpotLight(0xffffff, 1.0);
    spotLight.position.set(0, -90, -50);
    spotLight.castShadow = true;
    spotLight.shadow.mapSize.width = 1024;
    spotLight.shadow.mapSize.height = 1024;
    spotLight.shadow.bias = -0.0001;
    this.scene.add(spotLight);
    
    // Secondary spotlight for additional illumination
    const spotLightB = new THREE.SpotLight(0xffffff, 0.7);
    spotLightB.position.set(-50, 70, -10);
    spotLightB.castShadow = true;
    spotLightB.shadow.mapSize.width = 512;
    spotLightB.shadow.mapSize.height = 512;
    this.scene.add(spotLightB);
    
    // Add a subtle directional light for better depth
    const directionalLight = new THREE.DirectionalLight(0xffffee, 0.3);
    directionalLight.position.set(1, 1, 1);
    directionalLight.castShadow = true;
    
    // Configure directional light shadow camera
    directionalLight.shadow.camera.near = 0.1;
    directionalLight.shadow.camera.far = 10;
    directionalLight.shadow.camera.right = 5;
    directionalLight.shadow.camera.left = -5;
    directionalLight.shadow.camera.top = 5;
    directionalLight.shadow.camera.bottom = -5;
    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;
    
    this.scene.add(directionalLight);
  }
  
  /**
   * Set up mouse event handlers
   */
  setupMouseHandlers() {
    this.canvas.addEventListener('mousemove', this.onMouseMove.bind(this));
    this.canvas.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.canvas.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }
  
  /**
   * Update mouse position handler
   */
  onMouseMove(event) {
    const rect = this.canvas.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
  }
  
  /**
   * Mouse enter handler
   */
  onMouseEnter() {
    this.mouseInCanvas = true;
  }
  
  /**
   * Mouse leave handler
   */
  onMouseLeave() {
    this.mouseInCanvas = false;
  }
  
  /**
   * Handle window resize
   */
  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }
  
  /**
   * Set up window resize handler
   */
  setupResizeHandler() {
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }
  
  /**
   * Create and initialize particle system
   */
  setupParticles() {
    const { count, radius, size, speed } = CONFIG.particles;
    
    // Create geometry and attribute arrays
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(count * 3);
    const sizes = new Float32Array(count);
    
    // Fill arrays with random positions and sizes
    for (let i = 0; i < count; i++) {
      // Position particles in a spherical volume
      const theta = Math.random() * Math.PI * 2; // 0 to 2π
      const phi = Math.acos(2 * Math.random() - 1); // 0 to π
      
      positions[i * 3] = radius * Math.sin(phi) * Math.cos(theta); // x
      positions[i * 3 + 1] = radius * Math.sin(phi) * Math.sin(theta); // y
      positions[i * 3 + 2] = radius * Math.cos(phi); // z
      
      // Random sizes
      sizes[i] = Math.random() * (size.max - size.min) + size.min;
      
      // Store particle animation data
      this.particlesData.push({
        velocity: new THREE.Vector3(
          (Math.random() - 0.5) * speed,
          (Math.random() - 0.5) * speed,
          (Math.random() - 0.5) * speed
        ),
        initialY: positions[i * 3 + 1]
      });
    }
    
    // Set buffer attributes
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
    
    // Create particle material
    const texture = new THREE.TextureLoader().load(CONFIG.resources.particleTexture);
    const material = new THREE.PointsMaterial({
      size: 0.05,
      color: 0xffffff,
      transparent: true,
      opacity: 0.4,
      map: texture,
      blending: THREE.AdditiveBlending,
      depthWrite: false
    });
    
    // Create and add particle system
    this.particles = new THREE.Points(geometry, material);
    this.scene.add(this.particles);
  }
  
  /**
   * Load 3D models of leaves
   */
  loadModels() {
    const mtlLoader = new MTLLoader();
    const objLoader = new OBJLoader();
    const { mtl, texture, models } = CONFIG.resources;
    
    mtlLoader.load(mtl, (materials) => {
      materials.preload();
      objLoader.setMaterials(materials);
      
      // Load texture
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(texture, (tex) => {
        // Load each model
        let loadedCount = 0;
        
        models.forEach((modelUrl, index) => {
          objLoader.load(modelUrl, (obj) => {
            loadedCount++;
            
            // Create leaf data object
            const leaf = { 
              model: obj, 
              float: parseFloat((Math.random() * (CONFIG.leaves.floatMax - CONFIG.leaves.floatMin) + CONFIG.leaves.floatMin).toFixed(2)) 
            };
            
            this.leaves.push(leaf);
            
            // Apply texture and shadow settings
            obj.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                child.material.map = tex;
                child.castShadow = true;
                child.receiveShadow = true;
                child.material.side = THREE.DoubleSide;
                child.material.shadowSide = THREE.BackSide;
              }
            });
            
            // Add model to scene
            this.scene.add(obj);
            
            // Start animation when all models are loaded
            if (loadedCount === models.length) {
              document.querySelector('.leaves-loader')?.remove();
              this.startAnimation();
            }
          });
        });
      });
    });
  }
  
  /**
   * Start animation loop
   */
  startAnimation() {
    this.renderer.render(this.scene, this.camera);
    this.animate();
  }
  
  /**
   * Animation loop
   */
  animate() {
    this.renderer.setAnimationLoop(() => {
      // Render scene
      this.renderer.render(this.scene, this.camera);
      
      // Get elapsed time
      const time = this.clock.getElapsedTime();
      
      // Update particles
      this.updateParticles(time);
      
      // Update leaves
      this.updateLeaves(time);
    });
  }
  
  /**
   * Update particle positions
   */
  updateParticles(time) {
    const positions = this.particles.geometry.attributes.position.array;
    const { count } = CONFIG.particles;
    
    for (let i = 0; i < count; i++) {
      // Update positions based on velocity
      positions[i * 3] += this.particlesData[i].velocity.x;
      positions[i * 3 + 1] += this.particlesData[i].velocity.y + Math.sin(time * 0.5 + i) * 0.001; // Slight sine wave motion
      positions[i * 3 + 2] += this.particlesData[i].velocity.z;
      
      // Keep particles within bounds
      const distance = Math.sqrt(
        positions[i * 3] ** 2 + 
        positions[i * 3 + 1] ** 2 + 
        positions[i * 3 + 2] ** 2
      );
      
      if (distance > 5) {
        // Reset position to a random location within the sphere
        const { radius } = CONFIG.particles;
        const theta = Math.random() * Math.PI * 2;
        const phi = Math.acos(2 * Math.random() - 1);
        
        positions[i * 3] = radius * Math.sin(phi) * Math.cos(theta);
        positions[i * 3 + 1] = radius * Math.sin(phi) * Math.sin(theta);
        positions[i * 3 + 2] = radius * Math.cos(phi);
        
        // Reset velocity
        this.particlesData[i].velocity.set(
          (Math.random() - 0.5) * CONFIG.particles.speed,
          (Math.random() - 0.5) * CONFIG.particles.speed,
          (Math.random() - 0.5) * CONFIG.particles.speed
        );
      }
    }
    
    this.particles.geometry.attributes.position.needsUpdate = true;
    
    // Rotate particle system slightly for more natural movement
    this.particles.rotation.y += 0.0003;
  }
  
  /**
   * Update leaf positions and rotations
   */
  updateLeaves(time) {
    this.leaves.forEach((leaf, i) => {
      const model = leaf.model;
      
      // Initialize leaf properties if needed
      if (!leaf.originalPosition) {
        leaf.originalPosition = new THREE.Vector3().copy(model.position);
        leaf.currentOffset = new THREE.Vector3(0, 0, 0);
        leaf.targetOffset = new THREE.Vector3(0, 0, 0);
        leaf.rotationOffset = new THREE.Vector3(0, 0, 0);
        leaf.targetRotation = new THREE.Vector3(0, 0, 0);
        leaf.randomAngle = i * Math.PI / 4;
        leaf.uniqueFactor = (i % 3) * 0.2 + 0.8;
      }
      
      // Calculate base floating animation
      const floatOffset = Math.sin((2 * Math.PI * 2) + time) * leaf.float;
      
      // Handle mouse interaction
      if (this.mouseInCanvas) {
        this.applyMouseInteraction(leaf, i);
      } else {
        // Reset to original position when mouse isn't in canvas
        leaf.targetOffset.x = 0;
        leaf.targetOffset.y = 0;
        leaf.targetRotation.y = 0;
        leaf.targetRotation.z = 0;
      }
      
      // Apply smooth interpolation
      this.applyLeafInterpolation(leaf, floatOffset);
    });
  }
  
  /**
   * Calculate and apply mouse interaction effects
   */
  applyMouseInteraction(leaf, index) {
    const { repulsionRadius, repulsionStrength } = CONFIG.leaves;
    
    // Convert mouse position to 3D world coordinates
    const mouseWorldPos = new THREE.Vector3(this.mouse.x, this.mouse.y, 0);
    mouseWorldPos.unproject(this.camera);
    const dir = mouseWorldPos.sub(this.camera.position).normalize();
    const distance = -this.camera.position.z / dir.z;
    const pos = this.camera.position.clone().add(dir.multiplyScalar(distance));
    
    // Calculate distance between leaf and mouse position
    const distanceToMouse = pos.distanceTo(leaf.originalPosition);
    
    if (distanceToMouse < repulsionRadius) {
      // Calculate repulsion direction with consistent randomness
      const repulsionDir = new THREE.Vector3(
        (leaf.originalPosition.x - pos.x) + Math.cos(leaf.randomAngle) * 0.3,
        (leaf.originalPosition.y - pos.y) + Math.sin(leaf.randomAngle) * 0.3,
        0
      ).normalize();
      
      // Calculate repulsion force (stronger when closer)
      const repulsionForce = repulsionStrength * (1 - distanceToMouse / repulsionRadius);
      
      // Set target offset with individual movement patterns
      leaf.targetOffset.x = repulsionDir.x * repulsionForce * leaf.uniqueFactor;
      leaf.targetOffset.y = repulsionDir.y * repulsionForce * leaf.uniqueFactor;
      
      // Set target rotation
      leaf.targetRotation.y = repulsionForce * (1 + (index % 4) * 0.2);
      leaf.targetRotation.z = (index % 2) * repulsionForce * 0.2;
    } else {
      // Target returning to original position
      leaf.targetOffset.x = 0;
      leaf.targetOffset.y = 0;
      leaf.targetRotation.y = 0;
      leaf.targetRotation.z = 0;
    }
  }
  
  /**
   * Apply smooth interpolation to leaf position and rotation
   */
  applyLeafInterpolation(leaf, floatOffset) {
    const { lerpFactor } = CONFIG.leaves;
    const model = leaf.model;
    
    // Smoothly interpolate current offset toward target offset
    leaf.currentOffset.x += (leaf.targetOffset.x - leaf.currentOffset.x) * lerpFactor;
    leaf.currentOffset.y += (leaf.targetOffset.y - leaf.currentOffset.y) * lerpFactor;
    leaf.rotationOffset.y += (leaf.targetRotation.y - leaf.rotationOffset.y) * lerpFactor;
    leaf.rotationOffset.z += (leaf.targetRotation.z - leaf.rotationOffset.z) * lerpFactor;
    
    // Apply smooth movement
    model.position.x = leaf.originalPosition.x + leaf.currentOffset.x;
    model.position.y = leaf.originalPosition.y + floatOffset + leaf.currentOffset.y;
    model.rotation.y = floatOffset + leaf.rotationOffset.y;
    model.rotation.z = leaf.rotationOffset.z;
  }
}

// Initialize the application
document.addEventListener('DOMContentLoaded', () => {
  new LeavesApp('#leavesCanvas');
});